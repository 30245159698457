import { render, staticRenderFns } from "./watchman.vue?vue&type=template&id=70bec283&scoped=true&xmlns%3Ahttp=http%3A%2F%2Fwww.w3.org%2F1999%2Fxhtml&"
import script from "./watchman.vue?vue&type=script&lang=ts&"
export * from "./watchman.vue?vue&type=script&lang=ts&"
import style0 from "./watchman.vue?vue&type=style&index=0&id=70bec283&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70bec283",
  null
  
)

export default component.exports