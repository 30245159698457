













































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import popUpModal from '@/components/form/pop-up-modal.vue';
import FormSwitch from '@/components/form/form-switch.vue';
import progressBar from '@/components/form/progress-bar.vue';
import CountriesList from '@/components/form/countries-list.vue';
import COUNTRIES from '@/config/countires-list';
import DatePicker from '@/components/form/date-picker.vue';
import errorMessage from '@/components/form/error-message.vue';
import googleMap from '@/components/GoogleMapLoader.vue';
import { CASE_STATUS, WEBSITE_URL } from '@/constant/constants';
import { mapSettings } from '@/constant/mapSettings';
import { api } from '@/service/instance';
// import any = jasmine.any;

const storageLocation = {
  Lagerort1: '',
  Lagerort2: '',
  Lagerort3: '',
  Adresse: '',
  Nr: '',
  PLZ: '',
  Ort: '',
  country: '',
  MaximumStock: 0,
  Steel: false,
  ferroconcrete: false,
  Wood: false,
  LoadBearingChec: false,
  LoadBearingText: '',
  wallsFerroconcrete: false,
  Trapezoidal: false,
  stone: false,
  wallsChec: false,
  wallsText: '',
  storageArea: 0,
  OperatingTimes: '',
  von: '',
  bis: '',
  ThirdPartyAccess: false,
  fireProtection: false,
  Sprinkler: false,
  FireAlarm: false,
  automatically: false,
  manually: false,
  smokeDetector: false,
  HeatDetector: false,
  EDprotection: false,
  none: false,
  fence: false,
  wall: false,
  AccessControl: false,
  GuardService: false,
  FrequencyOfPatrols: '',
  alarmSystem: false,
  NotVds: false,
  VdsInfo: '',
  VdS: '',
  alarmPolice: false,
  alarmEmployee: false,
  alarmCenter: false,
  SecurityShack: false,
  VideoSurveillance: false,
  Inside: false,
  Outside: false,
  inventoriesCarriedOut: false,
  oftenInventories: '',
  lastPhysicalInventory: '' as any,
  WatchmanRemarks: '',
};

export default Vue.extend({
  name: 'watchman',
  components: {
    progressBar,
    popUpModal,
    FormSwitch,
    DatePicker,
    errorMessage,
    'countries-list': CountriesList,
    googleMap,
  },
  props: ['assesment', 'insuranceKey', 'caseStatus', 'progressBar', 'id', 'formData', 'isAPIObserver', 'AMSAPISendStatus'],

  data() {
    return {
      region: '',
      nextPath: 'logistics-insurance-price-card',
      modalOpen: false,
      mapModalOpen: false,
      modalTitle: '',
      modalBody: '',
      AGB: false,
      AGBError: false,
      ADSp17: false,
      ADSp16: false,
      ADSp03: false,
      VBGL: false,
      VBGU: false,
      ALB: false,
      Others: false,
      OthersText: '',
      insuranceFile: {
        name: '',
        base64: '',
        extension: '',
      },
      fileUploaded: false,
      ContractualAgreements: false,
      insuranceCoverageRequired: false,
      grossAnnual: 0,
      grossAnnualError: false,
      GeneralFreight: 0,
      ChilledGoods: 0,
      FrozenFood: false,
      TemperatureControlled: 0,
      over100k: false,
      HighQuality: 0,
      over300k: false,
      Bulk: 0,
      MovingGoods: 0,
      Art: 0,
      HeavyLift: 0,
      GoodsInTank: 0,
      artMoneyValuablesDocuments: 0,
      animalsAndPlants: 0,
      SonstigeText: 0,
      Sonstige: 0,
      SumSpecialTypes: 0,
      SumSpecialTypesError: false,
      subcontractors: false,
      likeInsuranceCoverage: false,
      VideoSurveillanceError: false,
      oftenInventoriesError: false,
      lastPhysicalInventoryError: false,
      InsuranceCoverage: '',
      storageLocations: [
        {
          ...storageLocation,
        },
      ],
      storageLocationsError: false,
      AdresseError: false,
      NrError: false,
      PLZError: false,
      OrtError: false,
      countryError: false,
      bearingPartsError: false,
      wallsError: false,
      FireAlarmError: false,
      enclosureError: false,
      alarmSystemError: false,
      ALBinsuranceFile: {
        originalName: '',
        name: '',
        fileUploaded: false,
        uploadPercentage: 0 as number,
      },
      VBGLinsuranceFile: {
        originalName: '',
        name: '',
        fileUploaded: false,
        uploadPercentage: 0 as number,
      },
      addresMap: '',
    };
  },
  watch: {
    GeneralFreight: 'calculateSumSpecialTypes',
    ChilledGoods: 'calculateSumSpecialTypes',
    TemperatureControlled: 'calculateSumSpecialTypes',
    HighQuality: 'calculateSumSpecialTypes',
    Bulk: 'calculateSumSpecialTypes',
    MovingGoods: 'calculateSumSpecialTypes',
    Art: 'calculateSumSpecialTypes',
    HeavyLift: 'calculateSumSpecialTypes',
    GoodsInTank: 'calculateSumSpecialTypes',
    artMoneyValuablesDocuments: 'calculateSumSpecialTypes',
    animalsAndPlants: 'calculateSumSpecialTypes',
    Sonstige: 'calculateSumSpecialTypes',
  },
  methods: {
    openMapModal(Adresse: string, Nr: string, PLZ: string, Ort: string, country: string) {
      this.region = COUNTRIES.filter((item) => item.Land === country)[0]['ISO-Code'];
      this.addresMap = `${Adresse} ${Nr} ${PLZ} ${Ort}`;
      this.mapModalOpen = true;
    },
    LagerortIsEmpty(Lagerort1: string, Lagerort2: string, Lagerort3: string) {
      return (Lagerort1 === '' && Lagerort2 === '' && Lagerort3 === '');
    },
    openModal(title: string, body: string) {
      this.modalTitle = title;
      this.modalBody = body;
      this.modalOpen = !this.modalOpen;
    },
    modalClose() {
      this.modalTitle = '';
      this.modalBody = '';
      this.modalOpen = false;
    },
    disableAllInputs() {
      const inputs = document.querySelectorAll('input');
      for (let i = 0; i < inputs.length; i += 1) {
        inputs[i].disabled = true;
      }

      const selects = document.querySelectorAll('select');
      for (let i = 0; i < selects.length; i += 1) {
        selects[i].disabled = true;
      }
    },
    onFileChange(event: any) {
      const labelFileMame = event.target.attributes.name.value;
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        switch (labelFileMame) {
          case 'ALBinsuranceFile':
            this.ALBinsuranceFile.fileUploaded = false;
            this.ALBinsuranceFile.uploadPercentage = 0;
            this.ALBinsuranceFile.originalName = event.target.files[0].name;
            break;
          case 'VBGLinsuranceFile':
            this.VBGLinsuranceFile.fileUploaded = false;
            this.VBGLinsuranceFile.uploadPercentage = 0;
            this.VBGLinsuranceFile.originalName = event.target.files[0].name;
            break;
          default:
            console.log('default');
            break;
        }
        this.insuranceFile.name = event.target.files[0].name;
        this.insuranceFile.base64 = reader.result!.toString();
        this.insuranceFile.extension = event.target.files[0].name.substr(
          event.target.files[0].name.lastIndexOf('.') + 1,
        );
      });
      reader.readAsDataURL(event.target.files[0]);
    },
    uploadFileToServer(name: string) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      const data = {
        id: this.id,
        path: 'watchman',
        // eslint-disable-next-line @typescript-eslint/camelcase
        investment_file: {
          ...this.insuranceFile,
        },
      };
      api.post('upload_logistics_file', data, {
        baseURL: WEBSITE_URL,
        onUploadProgress(progressEvent: any) {
          that.fileUploadPercent(name, (progressEvent.loaded / progressEvent.total) * 100);
          // that.uploadPercentage = (progressEvent.loaded / progressEvent.total) * 100;
        },
      }).then((result) => {
        this.fileUploadName(result.data.data, name, true);
      }).catch((error) => {
        this.fileUploadName('', name, false);
      });
    },
    fileUploadName(name: string, modelName: string, status: boolean) {
      switch (modelName) {
        case 'ALBinsuranceFile':
          this.ALBinsuranceFile.name = name;
          this.ALBinsuranceFile.fileUploaded = status;
          break;
        case 'VBGLinsuranceFile':
          this.VBGLinsuranceFile.name = name;
          this.VBGLinsuranceFile.fileUploaded = status;
          break;
        default:
          console.log('default');
          break;
      }
    },
    fileUploadPercent(name: string, value: number) {
      switch (name) {
        case 'ALBinsuranceFile':
          this.ALBinsuranceFile.uploadPercentage = value;
          break;
        case 'VBGLinsuranceFile':
          this.VBGLinsuranceFile.uploadPercentage = value;
          break;
        default:
          console.log('default');
          break;
      }
    },
    valueMore100(val: any) {
      if (val === '') {
        return 0;
      }
      if (val > 100) {
        return 100;
      }
      return val;
    },
    calculateSumSpecialTypes() {
      this.GeneralFreight = this.valueMore100(this.GeneralFreight);
      this.ChilledGoods = this.valueMore100(this.ChilledGoods);
      this.TemperatureControlled = this.valueMore100(this.TemperatureControlled);
      this.HighQuality = this.valueMore100(this.HighQuality);
      this.Bulk = this.valueMore100(this.Bulk);
      this.MovingGoods = this.valueMore100(this.MovingGoods);
      this.Art = this.valueMore100(this.Art);
      this.HeavyLift = this.valueMore100(this.HeavyLift);
      this.GoodsInTank = this.valueMore100(this.GoodsInTank);
      this.artMoneyValuablesDocuments = this.valueMore100(this.artMoneyValuablesDocuments);
      this.animalsAndPlants = this.valueMore100(this.animalsAndPlants);
      this.Sonstige = this.valueMore100(this.Sonstige);

      this.SumSpecialTypes = this.GeneralFreight + this.ChilledGoods + this.TemperatureControlled + this.HighQuality + this.Bulk + this.MovingGoods + this.Art + this.HeavyLift + this.GoodsInTank + this.artMoneyValuablesDocuments + this.animalsAndPlants + this.Sonstige;
    },
    dublicateRow(fillData: boolean) {
      let item;
      item = JSON.parse(JSON.stringify(storageLocation));
      if (fillData && this.storageLocations.length > 0) {
        item = JSON.parse(JSON.stringify(this.storageLocations[this.storageLocations.length - 1]));
      }
      this.storageLocations.push({
        ...item,
      });
    },
    setValues(formData: any) {
      this.AGB = formData.AGB;
      this.ADSp17 = formData.ADSp17;
      this.ADSp16 = formData.ADSp16;
      this.ADSp03 = formData.ADSp03;
      this.VBGL = formData.VBGL;
      this.VBGU = formData.VBGU;
      this.ALB = formData.ALB;
      this.Others = formData.Others;
      this.OthersText = formData.OthersText;
      this.ContractualAgreements = formData.ContractualAgreements;
      this.insuranceCoverageRequired = formData.insuranceCoverageRequired;
      this.grossAnnual = formData.grossAnnual;
      this.GeneralFreight = formData.GeneralFreight;
      this.ChilledGoods = formData.ChilledGoods;
      this.FrozenFood = formData.FrozenFood;
      this.TemperatureControlled = formData.TemperatureControlled;
      this.over100k = formData.over100k;
      this.HighQuality = formData.HighQuality;
      this.over300k = formData.over300k;
      this.Bulk = formData.Bulk;
      this.MovingGoods = formData.MovingGoods;
      this.Art = formData.Art;
      this.HeavyLift = formData.HeavyLift;
      this.GoodsInTank = formData.GoodsInTank;
      this.artMoneyValuablesDocuments = formData.artMoneyValuablesDocuments;
      this.animalsAndPlants = formData.animalsAndPlants;
      this.SonstigeText = formData.SonstigeText;
      this.Sonstige = formData.Sonstige;
      this.SumSpecialTypes = formData.SumSpecialTypes;
      this.subcontractors = formData.subcontractors;
      this.likeInsuranceCoverage = formData.likeInsuranceCoverage;
      this.InsuranceCoverage = formData.InsuranceCoverage;
      this.storageLocations = formData.storageLocations;
      formData.storageLocations.forEach((value: any, index: any) => {
        this.storageLocations[index].lastPhysicalInventory = new Date(value.lastPhysicalInventory);
        // this.storageLocations[index].lastPhysicalInventory = new Date(value.lastPhysicalInventory);
        // console.log(value);
      });
      this.ALBinsuranceFile = formData.ALBinsuranceFile;
      this.VBGLinsuranceFile = formData.VBGLinsuranceFile;
    },
    validations() {
      if (this.isAGBError && this.AGB) {
        this.AGBError = true;
        return false;
      }
      if (this.isgrossAnnualError) {
        this.grossAnnualError = true;
        return false;
      }
      if (this.isSumSpecialTypesError) {
        this.SumSpecialTypesError = true;
        return false;
      }
      let validationError = false;
      this.storageLocations.forEach((item) => {
        if (this.LagerortIsEmpty(item.Lagerort1, item.Lagerort2, item.Lagerort3)) {
          this.storageLocationsError = true;
          validationError = true;
        } else if (item.Adresse === '') {
          this.AdresseError = true;
          validationError = true;
        } else if (item.Nr === '') {
          this.NrError = true;
          validationError = true;
        } else if (item.PLZ === '') {
          this.PLZError = true;
          validationError = true;
        } else if (item.Ort === '') {
          this.OrtError = true;
          validationError = true;
        } else if (item.country === '') {
          this.countryError = true;
          validationError = true;
        } else if (!(item.Steel || item.ferroconcrete || item.Wood || item.LoadBearingChec)) {
          this.bearingPartsError = true;
          validationError = true;
        } else if (!(item.wallsFerroconcrete || item.Trapezoidal || item.stone || item.wallsChec)) {
          this.wallsError = true;
          validationError = true;
        } else if (!(item.automatically || item.manually || item.smokeDetector || item.HeatDetector) && item.FireAlarm) {
          this.FireAlarmError = true;
          validationError = true;
        } else if (!(item.none || item.fence || item.wall || item.AccessControl)) {
          this.enclosureError = true;
          validationError = true;
        } else if (!item.VdS && item.alarmSystem) {
          this.alarmSystemError = true;
          validationError = true;
        } else if (!(item.Inside || item.Outside) && item.VideoSurveillance) {
          this.VideoSurveillanceError = true;
          validationError = true;
        } else if (item.oftenInventories === '' && item.inventoriesCarriedOut) {
          this.oftenInventoriesError = true;
          validationError = true;
        }
        // else if (item.lastPhysicalInventory === '' && item.inventoriesCarriedOut) {
        //   this.lastPhysicalInventoryError = true;
        //   validationError = true;
        // }
      });
      if (validationError) {
        return false;
      }
      return true;
    },
    scrollToFirstErrorElement() {
      const el = this.$el.getElementsByClassName('border-danger')[0];
      if (el) {
        el.scrollIntoView();
      }
    },
    onSubmit() {
      if (!this.validations()) {
        setTimeout(() => this.scrollToFirstErrorElement(), 500);
        return;
      }
      const data = {
        AGB: this.AGB,
        ADSp17: this.ADSp17,
        ADSp16: this.ADSp16,
        ADSp03: this.ADSp03,
        VBGL: this.VBGL,
        VBGU: this.VBGU,
        ALB: this.ALB,
        Others: this.Others,
        OthersText: this.OthersText,
        ContractualAgreements: this.ContractualAgreements,
        insuranceCoverageRequired: this.insuranceCoverageRequired,
        grossAnnual: this.grossAnnual,
        GeneralFreight: this.GeneralFreight,
        ChilledGoods: this.ChilledGoods,
        FrozenFood: this.FrozenFood,
        TemperatureControlled: this.TemperatureControlled,
        over100k: this.over100k,
        HighQuality: this.HighQuality,
        over300k: this.over300k,
        Bulk: this.Bulk,
        MovingGoods: this.MovingGoods,
        Art: this.Art,
        HeavyLift: this.HeavyLift,
        GoodsInTank: this.GoodsInTank,
        artMoneyValuablesDocuments: this.artMoneyValuablesDocuments,
        animalsAndPlants: this.animalsAndPlants,
        SonstigeText: this.SonstigeText,
        Sonstige: this.Sonstige,
        SumSpecialTypes: this.SumSpecialTypes,
        subcontractors: this.subcontractors,
        likeInsuranceCoverage: this.likeInsuranceCoverage,
        InsuranceCoverage: this.InsuranceCoverage,
        storageLocations: this.storageLocations,
        ALBinsuranceFile: this.ALBinsuranceFile,
        VBGLinsuranceFile: this.VBGLinsuranceFile,
      };
      this.$emit('watchman-data-changed', data);
      this.$router.push({
        name: this.nextPath,
        params: {
          id: this.$route.params.id,
        },
      });
    },
    goToBackUrl() {
      this.$router.push({
        name: this.backPath,
      }).catch((err: any) => {
        throw new Error(`Problem handling something: ${err}.`);
      });
    },
  },
  computed: {
    backPath(): string {
      let result = 'logistics-insurance';
      if (this.progressBar.forwardingActivities) {
        result = 'forwarding-activities';
      } else if (this.progressBar.roadCarrier) {
        result = 'road-haulier';
      }
      return result;
    },
    mapConfig() {
      return {
        ...mapSettings,
      };
    },
    apiKey(): string {
      return 'AIzaSyAb2lAk-kQpfds9ageaZFfQeA9XwOFqLTQ';
    },
    isAGBError(): boolean {
      return !(this.ADSp17 || this.ADSp16 || this.ADSp03 || this.VBGL || this.VBGU || this.ALB || this.Others || this.ContractualAgreements || this.insuranceCoverageRequired);
    },
    isgrossAnnualError(): boolean {
      return this.grossAnnual <= 0;
    },
    isSumSpecialTypesError(): boolean {
      return this.SumSpecialTypes !== 100;
    },
    // karteActive(): boolean {
    //   return
    // },
    isDisableAll(): boolean {
      if (this.isAPIObserver) {
        return true;
      }
      const result =
        this.caseStatus === CASE_STATUS.CONTRACT ||
        this.caseStatus === CASE_STATUS.SUGGESTION ||
        this.caseStatus === CASE_STATUS.CONTRACT_NO_SIGNATURE || this.AMSAPISendStatus !== null ||
        this.caseStatus === CASE_STATUS.OFFER_REQUEST_READY;

      if (result) {
        this.disableAllInputs();
      }
      return result;
    },
  },
  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    if (this.formData.watchmanFormData) {
      this.setValues(this.formData.watchmanFormData);
    }
  },
});
